<template>
  <div>
    <div class="uk-margin-top">
      <div class="uk-card uk-card-default kandang-card">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Nama Tahapan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  class="uk-input"
                  :value="dataActivity.name ? dataActivity.name : '-'"
                  type="text"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Urutan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  class="uk-input"
                  :value="dataActivity.sequence ? dataActivity.sequence : '-'"
                  type="text"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Jenis Kandang
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  class="uk-input"
                  :value="dataActivity.cage_category ? dataActivity.cage_category : '-'"
                  type="text"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Catatan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <textarea
                  id=""
                  name=""
                  class="uk-textarea"
                  cols="10"
                  rows="5"
                  :value="dataActivity.note ? dataActivity.note : '-'"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Total Kegiatan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  class="uk-input"
                  :value="dataActivity.detail_activity_log ? dataActivity.detail_activity_log.length : '0'"
                  type="text"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Terakhir Diubah
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  class="uk-input"
                  :value="dataActivity.updated_at ? formatDate(dataActivity.updated_at) : '-'"
                  type="text"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Diubah Oleh
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  class="uk-input"
                  :value="dataActivity.updated_by ? dataActivity.updated_by : '-'"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-margin-top">
      <div class="uk-card uk-card-default kandang-card">
        <div
          v-for="(log, i) in dataActivity.detail_activity_log"
          :key="i"
          class="uk-margin"
        >
          <div uk-flex>
            <h6 class="uk-text-bold">
              Kegiatan {{ i + 1 }}
            </h6>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Nama Kegiatan
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    class="uk-input"
                    :value="log.name ? log.name : '-'"
                    type="text"
                    disabled
                  >
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Nama Kategori
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    class="uk-input"
                    :value="log.activity_category ? log.activity_category : '-'"
                    type="text"
                    disabled
                  >
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Nama Sub-Kategori
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    class="uk-input"
                    :value="log.sub_activity_category ? log.sub_activity_category : '-'"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Tipe Input
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    class="uk-input"
                    :value="log.input_type ? log.input_type : '-'"
                    type="text"
                    disabled
                  >
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Waktu Pengerjaan
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    class="uk-input"
                    :value="log.time_start ? log.time_start : '-'"
                    type="text"
                    disabled
                  >
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Keterangan
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <textarea
                    id=""
                    name=""
                    class="uk-textarea"
                    cols="10"
                    rows="5"
                    :value="log.description ? log.description : '-'"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <hr>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small uk-margin-top"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <button
              class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
              type="button"
              @click="goToBack"
            >
              <img
                v-lazy="`${images}/icon/back.svg`"
                alt=""
                class="uk-margin-small-right"
              >
              Kembali
            </button>
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl uk-text-right">
            <button
              class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
              type="button"
              @click="showDeleteConfirmModal"
            >
              <img
                v-lazy="`${images}/icon/trash-white.svg`"
                alt=""
                class="uk-margin-small-right"
              >
              Hapus
            </button>
            <button
              class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
              type="button"
              @click="goToEdit"
            >
              <img
                v-lazy="`${images}/icon/edit.svg`"
                alt=""
                class="uk-margin-small-right"
              >
              Ubah
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import { dateUtcParanjeString } from '@/utils/formater'

export default {
  components: {
  },
  props: {
    dataActivity: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    goToBack() {
      this.$router.push(`/admin/tahapan`)
    },
    goToEdit() {
      this.$router.push(`/admin/tahapan/edit/${this.dataActivity.id}`)
    },
    showDeleteConfirmModal() {
      window.UIkit.modal('#modal-delete-confirm').show()
      this.setModalDelete(this.dataActivity)
    }
  }
}
</script>
